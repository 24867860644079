.mhView .mhGrid--home .lubokContentModule,
.mhView .mhGrid--page .lubokContentModule,
.mhView .mhGrid--page .lubokSubNav,
.mhView .mhGrid--productsTeaser {
  -webkit-transition: padding-top 0.3s ease-in 0.09s, grid-row-gap 0.18s ease-in 0.15s, margin 0.18s ease-in 0.15s, opacity 0.45s ease-in 0.09s;
  transition: padding-top 0.3s ease-in 0.09s, grid-row-gap 0.18s ease-in 0.15s, margin 0.18s ease-in 0.15s, opacity 0.45s ease-in 0.09s;
}
.mhView--state-loading .mhGrid--home .lubokContentModule,
.mhView--state-loading .mhGrid--page .lubokContentModule {
  margin-bottom: 6rem;
  opacity: 0 !important;
}
.mhView--state-loading .mhGrid--home .lubokContentModule:first-child,
.mhView--state-loading .mhGrid--page .lubokContentModule:first-child {
  padding-top: 6rem;
}
.mhView--state-loading .mhGrid--productsTeaser {
  padding-top: 6rem;
  grid-row-gap: 9rem;
  opacity: 0 !important;
}

/**
	 *  Grid-System
	 *
	 *  @changelog:
	 *  	2019-06-05	rename .textAlign to .text
	 *  	2019-06-05	rename .flexAlign to .flex 
	 *  	2018-04-13	refactor to generate classes with loops, use media-queries
	 *  	2018-03-27	added textAlign
	 *  	2017-11-07	removed useless code
	 *  	2017-10-04	added 12 col grid
	 *  	2017-09-26	added flexAlign vor vert and hori aligning
	 *  	2017-09-26	added row: nowrap
	 *  	2017-09-26	added col--fixedWidth
	 *  	2017-06-17	added md breakpoint 
	 *  	2017-03-01	init
	 */
/*
	@screen__xs-width : 500px;
	@screen__sm-width : 715px;
	@screen__md-width : 1130px;
	@screen__lg-width : 1365px;
	@screen__xl-width : 1950px;		
	@mediaQuery__xs   : ~"(max-width:" @screen__xs-width ~")";
	@mediaQuery__sm   : ~"(max-width:" @screen__sm-width ~")";
	@mediaQuery__md   : ~"(max-width:" @screen__md-width ~")";
	@mediaQuery__lg   : ~"(min-width:" @screen__lg-width ~")";
	@mediaQuery__xl   : ~"(min-width:" @screen__xl-width ~")";
	*/
@media (max-width: 500px) {
.hideFor--xs[data-v-b28388a2] {
    display: none !important;
}
}
@media (max-width: 715px) {
.hideFor--sm[data-v-b28388a2] {
    display: none !important;
}
}
@media (min-width: 715px) and (max-width: 1130px) {
.hideFor--md[data-v-b28388a2] {
    display: none !important;
}
}
@media (min-width: 1365px) and (max-width: 1950px) {
.hideFor--lg[data-v-b28388a2] {
    display: none !important;
}
}
@media (min-width: 1365px) {
.hideFor--lg[data-v-b28388a2] {
    display: none !important;
}
}
.showOnlyFor[data-v-b28388a2] {
  display: none;
}
[data-showBorders-3="true"] .showOnlyFor[data-v-b28388a2] {
  background-color: rgba(255, 255, 0, 0.2);
}
@media (max-width: 500px) {
.showOnlyFor--xs[data-v-b28388a2] {
    display: block;
}
}
@media (max-width: 715px) {
.showOnlyFor--sm[data-v-b28388a2] {
    display: block;
}
}
@media (min-width: 715px) and (max-width: 1130px) {
.showOnlyFor--md[data-v-b28388a2] {
    display: block;
}
}
@media (min-width: 1365px) and (max-width: 1950px) {
.showOnlyFor--lg[data-v-b28388a2] {
    display: block;
}
}
@media (min-width: 1365px) {
.showOnlyFor--lg[data-v-b28388a2] {
    display: block;
}
}
.text--left[data-v-b28388a2] {
  text-align: left;
}
.text--center[data-v-b28388a2] {
  text-align: center;
}
.text--right[data-v-b28388a2] {
  text-align: right;
}
.flex[data-v-b28388a2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex--row[data-v-b28388a2] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.flex--column[data-v-b28388a2] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flex--left[data-v-b28388a2] {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.flex--center[data-v-b28388a2] {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex--right[data-v-b28388a2] {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.flex--top[data-v-b28388a2] {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.flex--middle[data-v-b28388a2] {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex--bottom[data-v-b28388a2] {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.textColumns[data-v-b28388a2] {
  -webkit-column-fill: auto;
     -moz-column-fill: auto;
          column-fill: auto;
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem;
}
.textColumns--1[data-v-b28388a2] {
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1;
}
.textColumns--2[data-v-b28388a2] {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
}
.textColumns--3[data-v-b28388a2] {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
}
.textColumns--4[data-v-b28388a2] {
  -webkit-column-count: 4;
     -moz-column-count: 4;
          column-count: 4;
}
.row[data-v-b28388a2] {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
.row--noOverflow[data-v-b28388a2] {
  overflow: hidden;
}
.row--noWrap[data-v-b28388a2] {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.col[data-v-b28388a2] {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  /*
		&--0of3 {	width: 100%/3 * 0; padding-left: 0; padding-right: 0; overflow: hidden; }
		&--1of3 {	width: 100%/3 * 1;	}
		&--2of3 {	width: 100%/3 * 2;	}
		&--3of3 {	width: 100%/3 * 3;	}

		&--0of4 {	width: 100%/4 * 0; padding-left: 0; padding-right: 0; overflow: hidden; }
		&--1of4 {	width: 100%/4 * 1;	}
		&--2of4 {	width: 100%/4 * 2;	}
		&--3of4 {	width: 100%/4 * 3;	}
		&--4of4 {	width: 100%/4 * 4;	}

		&--0of2 {	width: 100%/2 * 0; padding-left: 0; padding-right: 0; overflow: hidden; }
		&--1of2 {	width: 100%/2 * 1;	}
		&--2of2 {	width: 100%/2 * 2;	}

		&--0of6 {	width: 100%/6 * 0; padding-left: 0; padding-right: 0; overflow: hidden; }
		&--1of6 {	width: 100%/6 * 1;	}
		&--2of6 {	width: 100%/6 * 2;	}
		&--3of6 {	width: 100%/6 * 3;	}
		&--4of6 {	width: 100%/6 * 4;	}
		&--5of6 {	width: 100%/6 * 5;	}
		&--6of6 {	width: 100%/6 * 6;	}

		&--0of8 {	width: 100%/8 * 0; padding-left: 0; padding-right: 0; overflow: hidden; }
		&--1of8 {	width: 100%/8 * 1;	}
		&--2of8 {	width: 100%/8 * 2;	}
		&--3of8 {	width: 100%/8 * 3;	}
		&--4of8 {	width: 100%/8 * 4;	}
		&--5of8 {	width: 100%/8 * 5;	}
		&--6of8 {	width: 100%/8 * 6;	}
		&--7of8 {	width: 100%/8 * 7;	}
		&--8of8 {	width: 100%/8 * 8;	}
		
		&--0of12 {	width: 100%/12 * 0; padding-left: 0; padding-right: 0; overflow: hidden; }
		&--1of12 {	width: 100%/12 * 1;	}
		&--2of12 {	width: 100%/12 * 2;	}
		&--3of12 {	width: 100%/12 * 3;	}
		&--4of12 {	width: 100%/12 * 4;	}
		&--5of12 {	width: 100%/12 * 5;	}
		&--6of12 {	width: 100%/12 * 6;	}
		&--7of12 {	width: 100%/12 * 7;	}
		&--8of12 {	width: 100%/12 * 8;	}
		&--9of12 {	width: 100%/12 * 9;	}
		&--10of12 {	width: 100%/12 * 10;}
		&--11of12 {	width: 100%/12 * 11;}
		&--12of12 {	width: 100%/12 * 12;}
		*/
}
.col--gutter[data-v-b28388a2] {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.col--gutterLeft[data-v-b28388a2] {
  padding-left: 0.375rem;
}
.col--gutterRight[data-v-b28388a2] {
  padding-right: 0.375rem;
}
.col--gutterTop[data-v-b28388a2] {
  padding-top: 0.375rem;
}
.col--gutterBottom[data-v-b28388a2] {
  padding-bottom: 0.375rem;
}
.col--noGrow[data-v-b28388a2] {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.col--noShrink[data-v-b28388a2] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.col--4of4[data-v-b28388a2] {
  width: 100%;
}
.col--3of4[data-v-b28388a2] {
  width: 75%;
}
.col--2of4[data-v-b28388a2] {
  width: 50%;
}
.col--1of4[data-v-b28388a2] {
  width: 25%;
}
.col--0of4[data-v-b28388a2] {
  width: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.col--8of8[data-v-b28388a2] {
  width: 100%;
}
.col--7of8[data-v-b28388a2] {
  width: 87.5%;
}
.col--6of8[data-v-b28388a2] {
  width: 75%;
}
.col--5of8[data-v-b28388a2] {
  width: 62.5%;
}
.col--4of8[data-v-b28388a2] {
  width: 50%;
}
.col--3of8[data-v-b28388a2] {
  width: 37.5%;
}
.col--2of8[data-v-b28388a2] {
  width: 25%;
}
.col--1of8[data-v-b28388a2] {
  width: 12.5%;
}
.col--0of8[data-v-b28388a2] {
  width: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.col--9of9[data-v-b28388a2] {
  width: 100%;
}
.col--8of9[data-v-b28388a2] {
  width: 88.88888889%;
}
.col--7of9[data-v-b28388a2] {
  width: 77.77777778%;
}
.col--6of9[data-v-b28388a2] {
  width: 66.66666667%;
}
.col--5of9[data-v-b28388a2] {
  width: 55.55555556%;
}
.col--4of9[data-v-b28388a2] {
  width: 44.44444444%;
}
.col--3of9[data-v-b28388a2] {
  width: 33.33333333%;
}
.col--2of9[data-v-b28388a2] {
  width: 22.22222222%;
}
.col--1of9[data-v-b28388a2] {
  width: 11.11111111%;
}
.col--0of9[data-v-b28388a2] {
  width: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.col--12of12[data-v-b28388a2] {
  width: 100%;
}
.col--11of12[data-v-b28388a2] {
  width: 91.66666667%;
}
.col--10of12[data-v-b28388a2] {
  width: 83.33333333%;
}
.col--9of12[data-v-b28388a2] {
  width: 75%;
}
.col--8of12[data-v-b28388a2] {
  width: 66.66666667%;
}
.col--7of12[data-v-b28388a2] {
  width: 58.33333333%;
}
.col--6of12[data-v-b28388a2] {
  width: 50%;
}
.col--5of12[data-v-b28388a2] {
  width: 41.66666667%;
}
.col--4of12[data-v-b28388a2] {
  width: 33.33333333%;
}
.col--3of12[data-v-b28388a2] {
  width: 25%;
}
.col--2of12[data-v-b28388a2] {
  width: 16.66666667%;
}
.col--1of12[data-v-b28388a2] {
  width: 8.33333333%;
}
.col--0of12[data-v-b28388a2] {
  width: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
@media (max-width: 1130px ) {
.col--md-4of4[data-v-b28388a2] {
    width: 100%;
}
.col--md-3of4[data-v-b28388a2] {
    width: 75%;
}
.col--md-2of4[data-v-b28388a2] {
    width: 50%;
}
.col--md-1of4[data-v-b28388a2] {
    width: 25%;
}
.col--md-0of4[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--md-8of8[data-v-b28388a2] {
    width: 100%;
}
.col--md-7of8[data-v-b28388a2] {
    width: 87.5%;
}
.col--md-6of8[data-v-b28388a2] {
    width: 75%;
}
.col--md-5of8[data-v-b28388a2] {
    width: 62.5%;
}
.col--md-4of8[data-v-b28388a2] {
    width: 50%;
}
.col--md-3of8[data-v-b28388a2] {
    width: 37.5%;
}
.col--md-2of8[data-v-b28388a2] {
    width: 25%;
}
.col--md-1of8[data-v-b28388a2] {
    width: 12.5%;
}
.col--md-0of8[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--md-9of9[data-v-b28388a2] {
    width: 100%;
}
.col--md-8of9[data-v-b28388a2] {
    width: 88.88888889%;
}
.col--md-7of9[data-v-b28388a2] {
    width: 77.77777778%;
}
.col--md-6of9[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--md-5of9[data-v-b28388a2] {
    width: 55.55555556%;
}
.col--md-4of9[data-v-b28388a2] {
    width: 44.44444444%;
}
.col--md-3of9[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--md-2of9[data-v-b28388a2] {
    width: 22.22222222%;
}
.col--md-1of9[data-v-b28388a2] {
    width: 11.11111111%;
}
.col--md-0of9[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--md-12of12[data-v-b28388a2] {
    width: 100%;
}
.col--md-11of12[data-v-b28388a2] {
    width: 91.66666667%;
}
.col--md-10of12[data-v-b28388a2] {
    width: 83.33333333%;
}
.col--md-9of12[data-v-b28388a2] {
    width: 75%;
}
.col--md-8of12[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--md-7of12[data-v-b28388a2] {
    width: 58.33333333%;
}
.col--md-6of12[data-v-b28388a2] {
    width: 50%;
}
.col--md-5of12[data-v-b28388a2] {
    width: 41.66666667%;
}
.col--md-4of12[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--md-3of12[data-v-b28388a2] {
    width: 25%;
}
.col--md-2of12[data-v-b28388a2] {
    width: 16.66666667%;
}
.col--md-1of12[data-v-b28388a2] {
    width: 8.33333333%;
}
.col--md-0of12[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
body[showborders1][data-v-b28388a2]::before {
    position: fixed;
    top: 0;
    left: 0;
    content: "@media:  md ";
    z-index: 2000;
    font-size: 12px;
    line-height: 1em;
    padding: 0.25em;
    background-color: cyan;
}
body[data-v-b28388a2] {
    content: "md";
}
}
@media (max-width: 715px ) {
.col--sm-4of4[data-v-b28388a2] {
    width: 100%;
}
.col--sm-3of4[data-v-b28388a2] {
    width: 75%;
}
.col--sm-2of4[data-v-b28388a2] {
    width: 50%;
}
.col--sm-1of4[data-v-b28388a2] {
    width: 25%;
}
.col--sm-0of4[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--sm-8of8[data-v-b28388a2] {
    width: 100%;
}
.col--sm-7of8[data-v-b28388a2] {
    width: 87.5%;
}
.col--sm-6of8[data-v-b28388a2] {
    width: 75%;
}
.col--sm-5of8[data-v-b28388a2] {
    width: 62.5%;
}
.col--sm-4of8[data-v-b28388a2] {
    width: 50%;
}
.col--sm-3of8[data-v-b28388a2] {
    width: 37.5%;
}
.col--sm-2of8[data-v-b28388a2] {
    width: 25%;
}
.col--sm-1of8[data-v-b28388a2] {
    width: 12.5%;
}
.col--sm-0of8[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--sm-9of9[data-v-b28388a2] {
    width: 100%;
}
.col--sm-8of9[data-v-b28388a2] {
    width: 88.88888889%;
}
.col--sm-7of9[data-v-b28388a2] {
    width: 77.77777778%;
}
.col--sm-6of9[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--sm-5of9[data-v-b28388a2] {
    width: 55.55555556%;
}
.col--sm-4of9[data-v-b28388a2] {
    width: 44.44444444%;
}
.col--sm-3of9[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--sm-2of9[data-v-b28388a2] {
    width: 22.22222222%;
}
.col--sm-1of9[data-v-b28388a2] {
    width: 11.11111111%;
}
.col--sm-0of9[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--sm-12of12[data-v-b28388a2] {
    width: 100%;
}
.col--sm-11of12[data-v-b28388a2] {
    width: 91.66666667%;
}
.col--sm-10of12[data-v-b28388a2] {
    width: 83.33333333%;
}
.col--sm-9of12[data-v-b28388a2] {
    width: 75%;
}
.col--sm-8of12[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--sm-7of12[data-v-b28388a2] {
    width: 58.33333333%;
}
.col--sm-6of12[data-v-b28388a2] {
    width: 50%;
}
.col--sm-5of12[data-v-b28388a2] {
    width: 41.66666667%;
}
.col--sm-4of12[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--sm-3of12[data-v-b28388a2] {
    width: 25%;
}
.col--sm-2of12[data-v-b28388a2] {
    width: 16.66666667%;
}
.col--sm-1of12[data-v-b28388a2] {
    width: 8.33333333%;
}
.col--sm-0of12[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
body[showborders1][data-v-b28388a2]::before {
    position: fixed;
    top: 0;
    left: 0;
    content: "@media:  sm ";
    z-index: 2000;
    font-size: 12px;
    line-height: 1em;
    padding: 0.25em;
    background-color: cyan;
}
body[data-v-b28388a2] {
    content: "sm";
}
}
@media (max-width: 500px ) {
.col--xs-4of4[data-v-b28388a2] {
    width: 100%;
}
.col--xs-3of4[data-v-b28388a2] {
    width: 75%;
}
.col--xs-2of4[data-v-b28388a2] {
    width: 50%;
}
.col--xs-1of4[data-v-b28388a2] {
    width: 25%;
}
.col--xs-0of4[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--xs-8of8[data-v-b28388a2] {
    width: 100%;
}
.col--xs-7of8[data-v-b28388a2] {
    width: 87.5%;
}
.col--xs-6of8[data-v-b28388a2] {
    width: 75%;
}
.col--xs-5of8[data-v-b28388a2] {
    width: 62.5%;
}
.col--xs-4of8[data-v-b28388a2] {
    width: 50%;
}
.col--xs-3of8[data-v-b28388a2] {
    width: 37.5%;
}
.col--xs-2of8[data-v-b28388a2] {
    width: 25%;
}
.col--xs-1of8[data-v-b28388a2] {
    width: 12.5%;
}
.col--xs-0of8[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--xs-9of9[data-v-b28388a2] {
    width: 100%;
}
.col--xs-8of9[data-v-b28388a2] {
    width: 88.88888889%;
}
.col--xs-7of9[data-v-b28388a2] {
    width: 77.77777778%;
}
.col--xs-6of9[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--xs-5of9[data-v-b28388a2] {
    width: 55.55555556%;
}
.col--xs-4of9[data-v-b28388a2] {
    width: 44.44444444%;
}
.col--xs-3of9[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--xs-2of9[data-v-b28388a2] {
    width: 22.22222222%;
}
.col--xs-1of9[data-v-b28388a2] {
    width: 11.11111111%;
}
.col--xs-0of9[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--xs-12of12[data-v-b28388a2] {
    width: 100%;
}
.col--xs-11of12[data-v-b28388a2] {
    width: 91.66666667%;
}
.col--xs-10of12[data-v-b28388a2] {
    width: 83.33333333%;
}
.col--xs-9of12[data-v-b28388a2] {
    width: 75%;
}
.col--xs-8of12[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--xs-7of12[data-v-b28388a2] {
    width: 58.33333333%;
}
.col--xs-6of12[data-v-b28388a2] {
    width: 50%;
}
.col--xs-5of12[data-v-b28388a2] {
    width: 41.66666667%;
}
.col--xs-4of12[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--xs-3of12[data-v-b28388a2] {
    width: 25%;
}
.col--xs-2of12[data-v-b28388a2] {
    width: 16.66666667%;
}
.col--xs-1of12[data-v-b28388a2] {
    width: 8.33333333%;
}
.col--xs-0of12[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
body[showborders1][data-v-b28388a2]::before {
    position: fixed;
    top: 0;
    left: 0;
    content: "@media:  xs ";
    z-index: 2000;
    font-size: 12px;
    line-height: 1em;
    padding: 0.25em;
    background-color: cyan;
}
body[data-v-b28388a2] {
    content: "xs";
}
}
@media (min-width: 1365px ) {
.col--lg-4of4[data-v-b28388a2] {
    width: 100%;
}
.col--lg-3of4[data-v-b28388a2] {
    width: 75%;
}
.col--lg-2of4[data-v-b28388a2] {
    width: 50%;
}
.col--lg-1of4[data-v-b28388a2] {
    width: 25%;
}
.col--lg-0of4[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--lg-8of8[data-v-b28388a2] {
    width: 100%;
}
.col--lg-7of8[data-v-b28388a2] {
    width: 87.5%;
}
.col--lg-6of8[data-v-b28388a2] {
    width: 75%;
}
.col--lg-5of8[data-v-b28388a2] {
    width: 62.5%;
}
.col--lg-4of8[data-v-b28388a2] {
    width: 50%;
}
.col--lg-3of8[data-v-b28388a2] {
    width: 37.5%;
}
.col--lg-2of8[data-v-b28388a2] {
    width: 25%;
}
.col--lg-1of8[data-v-b28388a2] {
    width: 12.5%;
}
.col--lg-0of8[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--lg-9of9[data-v-b28388a2] {
    width: 100%;
}
.col--lg-8of9[data-v-b28388a2] {
    width: 88.88888889%;
}
.col--lg-7of9[data-v-b28388a2] {
    width: 77.77777778%;
}
.col--lg-6of9[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--lg-5of9[data-v-b28388a2] {
    width: 55.55555556%;
}
.col--lg-4of9[data-v-b28388a2] {
    width: 44.44444444%;
}
.col--lg-3of9[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--lg-2of9[data-v-b28388a2] {
    width: 22.22222222%;
}
.col--lg-1of9[data-v-b28388a2] {
    width: 11.11111111%;
}
.col--lg-0of9[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--lg-12of12[data-v-b28388a2] {
    width: 100%;
}
.col--lg-11of12[data-v-b28388a2] {
    width: 91.66666667%;
}
.col--lg-10of12[data-v-b28388a2] {
    width: 83.33333333%;
}
.col--lg-9of12[data-v-b28388a2] {
    width: 75%;
}
.col--lg-8of12[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--lg-7of12[data-v-b28388a2] {
    width: 58.33333333%;
}
.col--lg-6of12[data-v-b28388a2] {
    width: 50%;
}
.col--lg-5of12[data-v-b28388a2] {
    width: 41.66666667%;
}
.col--lg-4of12[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--lg-3of12[data-v-b28388a2] {
    width: 25%;
}
.col--lg-2of12[data-v-b28388a2] {
    width: 16.66666667%;
}
.col--lg-1of12[data-v-b28388a2] {
    width: 8.33333333%;
}
.col--lg-0of12[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
body[showborders1][data-v-b28388a2]::before {
    position: fixed;
    top: 0;
    left: 0;
    content: "@media:  lg ";
    z-index: 2000;
    font-size: 12px;
    line-height: 1em;
    padding: 0.25em;
    background-color: cyan;
}
body[data-v-b28388a2] {
    content: "lg";
}
}
@media (min-width: 1950px ) {
.col--xl-4of4[data-v-b28388a2] {
    width: 100%;
}
.col--xl-3of4[data-v-b28388a2] {
    width: 75%;
}
.col--xl-2of4[data-v-b28388a2] {
    width: 50%;
}
.col--xl-1of4[data-v-b28388a2] {
    width: 25%;
}
.col--xl-0of4[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--xl-8of8[data-v-b28388a2] {
    width: 100%;
}
.col--xl-7of8[data-v-b28388a2] {
    width: 87.5%;
}
.col--xl-6of8[data-v-b28388a2] {
    width: 75%;
}
.col--xl-5of8[data-v-b28388a2] {
    width: 62.5%;
}
.col--xl-4of8[data-v-b28388a2] {
    width: 50%;
}
.col--xl-3of8[data-v-b28388a2] {
    width: 37.5%;
}
.col--xl-2of8[data-v-b28388a2] {
    width: 25%;
}
.col--xl-1of8[data-v-b28388a2] {
    width: 12.5%;
}
.col--xl-0of8[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--xl-9of9[data-v-b28388a2] {
    width: 100%;
}
.col--xl-8of9[data-v-b28388a2] {
    width: 88.88888889%;
}
.col--xl-7of9[data-v-b28388a2] {
    width: 77.77777778%;
}
.col--xl-6of9[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--xl-5of9[data-v-b28388a2] {
    width: 55.55555556%;
}
.col--xl-4of9[data-v-b28388a2] {
    width: 44.44444444%;
}
.col--xl-3of9[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--xl-2of9[data-v-b28388a2] {
    width: 22.22222222%;
}
.col--xl-1of9[data-v-b28388a2] {
    width: 11.11111111%;
}
.col--xl-0of9[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.col--xl-12of12[data-v-b28388a2] {
    width: 100%;
}
.col--xl-11of12[data-v-b28388a2] {
    width: 91.66666667%;
}
.col--xl-10of12[data-v-b28388a2] {
    width: 83.33333333%;
}
.col--xl-9of12[data-v-b28388a2] {
    width: 75%;
}
.col--xl-8of12[data-v-b28388a2] {
    width: 66.66666667%;
}
.col--xl-7of12[data-v-b28388a2] {
    width: 58.33333333%;
}
.col--xl-6of12[data-v-b28388a2] {
    width: 50%;
}
.col--xl-5of12[data-v-b28388a2] {
    width: 41.66666667%;
}
.col--xl-4of12[data-v-b28388a2] {
    width: 33.33333333%;
}
.col--xl-3of12[data-v-b28388a2] {
    width: 25%;
}
.col--xl-2of12[data-v-b28388a2] {
    width: 16.66666667%;
}
.col--xl-1of12[data-v-b28388a2] {
    width: 8.33333333%;
}
.col--xl-0of12[data-v-b28388a2] {
    width: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
body[showborders1][data-v-b28388a2]::before {
    position: fixed;
    top: 0;
    left: 0;
    content: "@media:  xl ";
    z-index: 2000;
    font-size: 12px;
    line-height: 1em;
    padding: 0.25em;
    background-color: cyan;
}
body[data-v-b28388a2] {
    content: "xl";
}
}
.notFoundView[data-v-b28388a2] {
  padding: 1em;
}


/*# sourceMappingURL=NotFoundView.317cda2d.css.map*/