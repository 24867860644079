





































@import "../less/includes/grid.include.less";

.notFoundView {
	padding: 1em;
}

@media @mediaQuery__xs {}
@media @mediaQuery__sm {}
@media @mediaQuery__md {}
@media @mediaQuery__lg {}
@media @mediaQuery__xl {}
