





















































































































































































































.mhView {
	//background-color: grey;
	//transition-duration: 3s !important;

	@duration : 0.3s;

	.mhGrid--home .lubokContentModule,
	.mhGrid--page .lubokContentModule,
	.mhGrid--page .lubokSubNav,
	.mhGrid--productsTeaser {
		transition: 
			padding-top @duration ease-in @duration*0.3,
			
			grid-row-gap @duration*0.6 ease-in @duration*0.5,
			margin @duration*0.6 ease-in @duration*0.5,
			
			opacity @duration*1.5 ease-in @duration*0.3;
	}

	&--state-loading {
		
		.mhGrid--home .lubokContentModule,
		.mhGrid--page .lubokContentModule {
			margin-bottom: 6rem;
			opacity: 0 !important;
			
			&:first-child {
				padding-top: 6rem; 
			}			
		}	

		.mhGrid--productsTeaser {
			padding-top: 6rem; 
			grid-row-gap: 9rem; 
			opacity: 0 !important;
		}

	}
	&--state-loaded {
		
		.mhGrid--productsTeaser {
			//padding-top: 0rem; 
			//grid-row-gap: 1rem; 
			//opacity: 1;
		}
		
		.mhGrid--home .lubokContentModule,
		.mhGrid--page .lubokContentModule {
			//margin-bottom: 0rem;
			//opacity: 1;		
		}
		
	}
	
}
.mhView__loadingSpinner {}
